@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&family=Tiny5&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Noto Sans", sans-serif;
}
/* Global styles for blog content */
.blog-description a {
  color: #3b82f6; /* Tailwind blue-500 */
  /* text-decoration: underline; */
}

.blog-description a:hover {
  color: #2563eb; /* Tailwind blue-600 */
}

.blog-description strong {
  font-weight: bold;
}


/* body::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
} */

/* .no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

.categories-on-home {
  padding: 0;
  max-width: 100%;
  display: flex;
  /* margin-top: 2rem; */
  justify-content: flex-start; /* Start items from the left */
  /* padding-left: 1rem; */
  overflow-x: scroll; /* Ensure horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrolling */
  white-space: nowrap; /* Prevent wrapping to next line */
}

.category {
  flex-basis: 13%;
  list-style: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 0 0 auto;
  margin-right: 2.1rem;
  margin-top: 20px;
}

.category-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .category-img {
    width: 62px;
    height: 62px;
    object-fit: cover;
    border-radius: 50%;
  }
  .categories-on-home {
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 1rem;
    margin-top: 1rem;
  }
  /* .category {
    margin-right: 0.7rem;
  } */
}

.category-name {
  margin-top: 0.3rem;
  font-size: 13px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@keyframes cart {
  0% {
    left: -10%;
  }
  40%,
  60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}

@keyframes box {
  0%,
  40% {
    top: -20%;
  }
  60% {
    top: 40%;
    left: 52%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}

@keyframes txt1 {
  0% {
    opacity: 1;
  }
  20%,
  100% {
    opacity: 0;
  }
}

@keyframes txt2 {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cart-button.clicked .fa-shopping-cart {
  animation: cart 1.5s ease-in-out forwards;
}
.cart-button.clicked .fa-box {
  animation: box 1.5s ease-in-out forwards;
}
.cart-button.clicked .add-to-cart {
  animation: txt1 1.5s ease-in-out forwards;
}
.cart-button.clicked .added {
  animation: txt2 1.5s ease-in-out forwards;
}

/* Custom toast styles */
.custom-toast {
  font-size: 1rem;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .custom-toast {
    font-size: 0.8rem;
    padding: 8px;
    width: 90%;
    margin: 0 auto;
  }
}

.Toastify__toast-container,
.Toastify__toast-container--top-right {
  top: 110px !important;
  z-index: 9999999934239282893298329999; /* Adjust the value as needed */
}

@media (max-width: 600px) {
  .Toastify__toast-container,
  .Toastify__toast-container--top-right {
    top: 130px !important;
    font-size: 0.9rem !important;
    width: 100% !important;
    border-radius: 8px;
  }
  .Toastify__toast-container,
  .Toastify__toast-container--top-right {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
  }
}


@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 60s linear infinite;
}

.animate-marquee.paused {
  animation-play-state: paused;
}




.custom-slider {
  height: 6px;
  background: #ddd;
  border-radius: 4px;
  position: relative;
}

.custom-thumb {
  height: 16px;
  width: 16px;
  background: #007bff;
  border-radius: 50%;
  cursor: grab;
  position: relative;
  top: -5px;
}

.custom-track {
  background: #6b39ff;
  height: 6px;
  border-radius: 4px;
}


.blurred {
  /* filter: blur(3px); */
  pointer-events: none; /* Disables interactions with blurred elements */
}

.blurred-overlay {
  position: absolute;
  inset: 10; 
  background-color: rgba(53, 46, 46, 0.5); /* White with 50% transparency */
  z-index: 111; /* Ensures it appears above the content */
}
